.banner {
  padding: 10px 16px;
  background: rgb(243, 243, 243);
  color: #6d6565;
  font-family: monospace;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

/* Page content */
.content {
  padding: 16px;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky+.content {
  padding-top: 102px;
}