.editor-box {
  background-color: #f5f6f7;
  padding: 20px;
  border-radius: 5px;
}

.float-right {
  float: right;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.alert {
  color: rgb(151, 23, 23);
  font-size: 14px;
  margin-top: 5px;
  position: absolute;
}

.add-word {
  padding: 0;
  margin: 0;
}

.citation-box {
  background-color: #f5f6f7;
  padding: 20px;
  border-radius: 5px;
}

.text-grid {
  display: grid;
  column-gap: 10px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}

.preview {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 5px;
  border: 1px solid #9E9E9E;
  height: 100%;
  padding: 20px 20px 0px 20px;
}

.submitter {
  position: relative;
  /* bottom: 5px; */
  color: gray;
  font-family: Newsreader;
}

@media screen and (max-width: 1000px) {
  .text-grid {
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-columns: 1fr;
  }
}