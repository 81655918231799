.button {
  width: 140px;
  height: 30px;
  border-radius: 0px;
  text-align: center;
  font-family: Newsreader;
  background: #EBECED;
  border: 1px solid #000000;
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
}

.button:hover {
  opacity: 0.6;
}

/* Solid border */
.solid {
  border: 0.5px solid #FBF3DA;
  margin-top: 2px;
  margin-bottom: 2px;
}

.wallet-connect {
  width: 300px;
  background-color: #EBECED;
  margin: 8px;
  padding: 8px;
  border-radius: 8px;
  font-size: 20px;
}

.ens-avatar {
  width: 50px;
  border-radius: 20px;
  float: left;
}

.account-info {
  float: right;
  margin-left: 4px;
  margin-right: 4px;
}

.sign-button:disabled {
  opacity: 0.6;
}