.search-button {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 200%;
  width: 80px;

  background: #EBECED;
  opacity: 0.4;
  border: 1px solid #000000;
  box-sizing: border-box;
}

.search-bar {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 200%;
  width: 250px;
  background:  #FFFFFF;
  border:  1px solid #EBECED;
  box-sizing:  border-box;
  margin-right: 5px;
}

.search-button:hover {
  opacity: 0.6;
}

.search-bar:active,
.search-bar:focus {
  opacity: 0.6;
  outline: 0;
  border: 1.5px solid #acacac;
}

/* Solid border */
.solid {
  border: 0.5px solid #FBF3DA;
  margin-top: 2px;
  margin-bottom: 2px;
}