.word-title {
  font-family: Newsreader;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 150%;
  text-align: center;
}

.grid-container {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 33% 33% 33%;
  justify-content: space-evenly;
  grid-row-gap: 20px;
}

.meaning-card {
  padding: 25px;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #878682;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 500px;
  overflow-y: scroll;
}

.action {
  text-align: end;
  margin-top: 5px;
}

.see-more {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5rem;

  background: #FFFFFF;
  opacity: 0.5;
  border: 1px solid #000000;
  box-sizing: border-box;
  background: #FFFFFF;
}

@media screen and (max-width: 1000px) {
  .grid-container {
    display: grid;
    grid-template-columns: 100%;
    padding-left: 1em;
  }
}