@import url('https://fonts.googleapis.com/css2?family=DM+Sans');
@import url('https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Newsreader:ital,wght@0,200;0,300;0,400;0,500;0,700;1,200;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Newsreader:ital,wght@0,200;0,300;0,400;0,500;0,700;1,200;1,300;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;1,100;1,200;1,300;1,800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.decorate {
  color: #888888;
  text-decoration: underline;
}

.decorate:hover {
  color: #646464;
  text-decoration: underline;
}


h1 {
  font-family: Newsreader;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 40px;

  color: #383838;
}


h2 {
  font-family: Newsreader;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;

  color: #383838;
}

.primary-button {
  height: 36px;
  min-width: 36px;
  background: #EBECED;
  opacity: 0.5;
  border: 1px solid #000000;

  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 15x;
  line-height: 150%;

  border: 1px solid #000000;
  background: #FFFFFF;

  padding-top: 0.5px;
  padding-bottom: 0.5px;
  padding-left: 3px;
  padding-right: 3px;
}
