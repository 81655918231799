.header {
  display: flex;
  margin-bottom: 8px;
  font-family: DM Sans,serif;
  font-size: 1.15rem;
  line-height: 1.65rem;
  text-align: left;
}

.logo {
  margin-top: 2em;
  min-width: 20em;
}

.description {
  display: flex;
  margin-top: 4em;
  margin-bottom: 2em;
  margin-left: 10px;
  padding: 4px
}

@media screen and (max-width: 1000px) {
  .logo{
    margin-top: 28px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
  }
  .header {
    display: grid;
    column-gap: 10px;
    margin-bottom: 20px;
    font-family: DM Sans,serif;
    font-size: 1.15rem;
    line-height: 1.65rem;
    text-align: left;
  }

  .description {
    margin-top: 1em;
  }
}