.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.top-action-container {
  position: absolute;
  display: flex;
  direction: row;
  padding: 8px;
  right: 10px;
  top: 4.5em;
  align-items: center;
  background: rgb(255, 255, 255);
}

div[class^="Snackbar_snackbar-wrapper"] {
  z-index: 1100;
}

@media screen and (max-width: 1000px) {
  .top-action-container {
    top: 7em;
  }
}