.vote-container {
  text-align: start;
  position: relative;
}

.vote-content {
  top: 0px;
  height: 100%;
}

/* Solid border */
.solid {
  border: 0.5px solid #FBF3DA;
  margin-top: 2px;
  margin-bottom: 2px;
}

.grid {
  width: 100%;
  grid-template-columns: 1fr auto;
  column-gap: 10px;
  padding: 20px;
}

.vote-modal {
  background: #F4F4F4;
  border: 0.5px solid #E8E8E8;
}

.meaning-box {
  position: relative;
  height: 390px;
  width: 100%;
  padding: 20px;

  background: #FFFFFF;
  border: 1px solid #878682;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.meaning-box .text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 320px;
  overflow-y: scroll;
}

.vote-box {
  height: 390px;
  min-width: 350px;
  width: 100%;
  padding: 20px;

  background: #FFFFFF;
  border: 1px solid #878682;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.action-area {
  display: flex;
  padding: 10px;
  padding-bottom: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.action-text {
  font-family: Newsreader;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height */

  margin-right: 10px;
  color: #525252;

  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.btn-group button {
  background-color: #FFFFFF;
  /* white background */
  border: 1px solid #BCBCBC;
  /* gray border */
  padding: 10px 24px;
  /* Some padding */
  border-radius: 10px;
  cursor: pointer;
  /* Pointer/hand icon */
  margin-top: 5px;
}

/* upvote */
.btn-group button:is(:first-child) {
  border-radius: 10px 00px 0px 10px;
}

.btn-group button:is(:first-child):hover:not(:disabled) {
  background-color: rgba(192, 225, 165, 0.2);
}

/* downvote */
.btn-group button:is(:last-child) {
  border-radius: 0px 10px 10px 0px;
}

.btn-group button:is(:last-child):hover:not(:disabled) {
  background-color: rgba(231, 162, 162, 0.2);
}

.btn-group button:disabled {
  background-color: rgb(245, 243, 243);
  pointer-events: none;
}

/* Clear floats (clearfix hack) */
.btn-group:after {
  content: "";
  clear: both;
  display: table;
}

.vote-list-container {
  position: relative;
  height: 100%;
}

.vote-list-items {
  margin-top: 15px;
  overflow-y: scroll;
  height: calc(100% - 50px);
}

.vote-count {
  position: absolute;
  align-items: center;
  bottom: 10px;
  text-align: center;
  width: 100%;
  padding: 4px;
  justify-content: center;
  display: flex;

  font-family: Inknut Antiqua;
  color: #7A7A7A;
}

.vote-label {
  font-family: Raleway;
  font-style: bold;
  font-size: 16px;
  line-height: 12px;

  color: #919090;
  text-align: center;
}

.vote-row {
  font-family: Raleway;
  font-weight: normal;
  font-size: 20px;

  margin-bottom: 15px;
  color: #8B8B8B;

  display: flex;
  justify-content: space-between;
}

.vote-row a {
  text-decoration: underline;
}

.submitter {
  position: absolute;
  bottom: 5px;
  color: gray;
  font-family: Newsreader;
}

.monospace {
  font-family: monospace;
}

@media screen and (max-width: 1000px) {
  .vote-modal {
    max-height: 80vh;
    overflow-y: scroll;
  }

  .grid {
    row-gap: 10px;
    grid-template-columns: minmax(0, 1fr);
  }

  .meaning-box {
    min-height: 150px;
    height: 100%;
  }

  .meaning-box .text {
    height: 100%;
    margin-bottom: 20px;
  }

  .see-also {
    margin-bottom: 2em;
  }

  .vote-box {
    height: 100%;
    min-height: 150px;
    max-height: 500px;
    min-width: 100px;
  }

  .vote-list-items {
    margin-top: 15px;
    height: 100%;
    margin-bottom: 10px;
  }

  .action-text {
    font-size: 15px;
    margin-right: 5px;
  }

  .btn-group button:is(:first-child),
  .btn-group button:is(:last-child) {
    border-radius: 10px;
  }

  .btn-group button {
    padding: 10px 15px;
    /* Some padding */
    border-radius: 10px;
    cursor: pointer;
    /* Pointer/hand icon */
    margin-top: 5px;
  }
}